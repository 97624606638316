import jsx from './jsx';
import { getElementsByClassName, skeleton } from './utils';

interface Reply {
  post: {
    uri: string;
    author: {
      displayName: string;
      handle: string;
      avatar: string;
    };
    record: {
      createdAt: string;
      text: string;
      facets: {
        index: {
          byteStart: number;
          byteEnd: number;
        };
        features: {
          uri: string; // This only applies to app.bsky.richtext.facet#link - need to expand this to handle other items
        }[];
      }[];
    };
  };
}

const Post = (reply: Reply) => {
  const postRecord = reply.post;

  const postId = postRecord.uri.split('/').pop();
  let postText = postRecord.record.text;
  if (postRecord.record.facets) {
    postRecord.record.facets.forEach((facet) => {
      const index = facet.index;
      const feature = facet.features[0].uri; // Naive?
      postText =
        postText.substring(0, index.byteStart) +
        feature +
        postText.substring(index.byteEnd);
    });
  }

  return (
    <div className="comment">
      <div className="commentAvatar">
        <img src={postRecord.author.avatar} />
      </div>
      <div className="content">
        <p className="commentHeader">
          <a
            href={
              postId
                ? `https://bsky.app/profile/${reply.post.author.handle}/post/${postId}`
                : `https://bsky.app/profile/${reply.post.author.handle}/`
            }
            target="_blank"
            rel="noreferrer"
            className="author"
          >
            {postRecord.author.displayName}
          </a>
          <span className="date">
            {new Date(postRecord.record.createdAt).toLocaleString()}
          </span>
        </p>
        <p className="commentBody">
          {...postText.split('\n').map((chunk) => (
            <span>
              {chunk}
              <br />
            </span>
          ))}
        </p>
      </div>
    </div>
  );
};

export const setupBlueSkyComments = async () => {
  const commentElement = getElementsByClassName('blueskyComments')?.[0];
  const renderTarget = getElementsByClassName(
    'blueskyCommentsRenderTarget',
  )?.[0];

  if (commentElement && renderTarget) {
    renderTarget.append(skeleton('100%', '100px'));
    const postId = commentElement.getAttribute('data-blueskyId');

    if (postId) {
      const ATURI = encodeURI(
        `at://did:plc:lpxtxymhlfbs5foxba7mfewt/app.bsky.feed.post/${postId}`,
      );

      const request = await fetch(
        `https://public.api.bsky.app/xrpc/app.bsky.feed.getPostThread?uri=${ATURI}`,
        {},
      );

      const response = await request.json();
      const replies = response?.thread?.replies as Reply[];

      console.log('Replies', replies);

      if (replies) {
        renderTarget.innerHTML = '';
        renderTarget.append(...replies.map((reply) => Post(reply)));
      } else {
        renderTarget.innerHTML = '';
      }
    }
  }
};
