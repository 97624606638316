import { Attributes, e } from './elements/element';

/** @internal too be called by jsxFactory only */
export default <T extends keyof HTMLElementTagNameMap>(
  tag: T,
  props: Attributes<T> | null,
  ...children: (string | HTMLElement)[]
) => {
  return e(tag, props, children);
};
