export const handleGalleryImages = () => {
  const images = document.querySelectorAll('.galleryImage');

  const loadImage = (image: HTMLImageElement) => {
    image.src = image.getAttribute('finalSrc') || '';
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        loadImage(entry.target as HTMLImageElement);
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.05,
  });

  images.forEach((img) => {
    observer.observe(img);

    img.addEventListener('click', (event) => {
      const image = event.target as HTMLImageElement;

      image.onerror = () => {
        console.warn('Could not get LG version of image!');
        image.src = image.getAttribute('finalSrc') || '';
      };

      image.src = image.getAttribute('hdSrc') || '';
    });
  });
};
