import jsx from './jsx';
import { getElementsByClassName, skeleton } from './utils';

interface Post {
  title: string;
  emoji?: string;
  link: string;
  published: Date;
  updated: Date;
  content: string;
  daysSinceUpdate: number;
  showUpdated: boolean;
}

const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
const daysToShowUpdated = 30; // Updates less than x days old will be highlighted

const renderPostList = <T,>(
  selectedPostOption: keyof T,
  postOptions: Record<keyof T, Post[]>,
  target: HTMLDivElement,
) => {
  let updatedAnimationOffset = 0;

  target.replaceChildren(
    <h2>{selectedPostOption}</h2>,
    <div className="postListCategories">
      <span>Category:</span>
      {...Object.keys(postOptions).map((postOption) => (
        <button
          className={postOption === selectedPostOption ? 'active' : ''}
          onclick={() =>
            renderPostList(postOption as keyof T, postOptions, target)
          }
        >
          {postOption}
        </button>
      ))}
    </div>,
    <ul className="postsList">
      {...postOptions[selectedPostOption].length
        ? postOptions[selectedPostOption].map((post) => {
            if (post.showUpdated) {
              updatedAnimationOffset += 100;
            }

            return (
              <li>
                {post.emoji || '📝'}
                <a href={post.link.replace('https://pfy.ch', '')}>
                  <span>
                    <span className="date">
                      {post.published.toLocaleDateString()} -{' '}
                    </span>
                    {post.title.replaceAll('&amp;', '&')}
                  </span>
                </a>
                {post.showUpdated ? (
                  <span
                    className="updated"
                    style={`--animation-offset: ${updatedAnimationOffset}ms`}
                  >
                    <div className="sparkle">✨</div>
                    <span className="updatedText">
                      Updated{' '}
                      {post.daysSinceUpdate === 0
                        ? 'Today!'
                        : `${post.daysSinceUpdate} day${post.daysSinceUpdate === 1 ? '' : 's'} ago!`}
                    </span>
                  </span>
                ) : (
                  ''
                )}
              </li>
            );
          })
        : [<li>No Posts in category!</li>]}
    </ul>,
  );
};

export const setupRecentPosts = async () => {
  const recentPosts =
    getElementsByClassName<HTMLDivElement>('recentPosts')?.[0];

  recentPosts?.append(skeleton('100%', '500px'));

  if (recentPosts) {
    const rssXML = await fetch('/rss.xml');
    const rssXMLText = await rssXML.text();
    const rssXMLDoc = new DOMParser().parseFromString(rssXMLText, 'text/xml');
    const items = rssXMLDoc.querySelectorAll('entry');

    recentPosts.innerHTML = '';

    const posts: Post[] = [...(items || [])].map((item) => {
      const postTitle = item.querySelector('title')?.innerHTML as string;
      const postLink = item.querySelector('link')?.innerHTML as string;
      const postEmoji = item.querySelector('emoji')?.innerHTML as string;
      const postContent = item.querySelector('content')?.innerHTML as string;
      const updatedDate = new Date(
        item.querySelector('updated')?.innerHTML as string,
      );
      const postDate = new Date(
        item.querySelector('published')?.innerHTML as string,
      );
      const daysSinceUpdate = Math.round(
        Math.abs((Date.now().valueOf() - updatedDate.valueOf()) / oneDay),
      );

      return {
        title: postTitle,
        emoji: postEmoji,
        link: postLink,
        published: postDate,
        updated: updatedDate,
        content: postContent,
        daysSinceUpdate,
        showUpdated: daysSinceUpdate < daysToShowUpdated,
      };
    });

    renderPostList(
      'All',
      {
        All: posts.filter((post) => {
          return post.published.valueOf() > 0;
        }),
        'Updated this Month': posts
          .filter((post) => {
            return post.showUpdated;
          })
          .sort((a, b) => b.updated.valueOf() - a.updated.valueOf()),
        Programming: posts.filter((post) => post.emoji === '💻'),
        Photography: posts.filter((post) =>
          ['✈️', '📷', '🎤'].includes(post.emoji as string),
        ),
        Games: posts.filter((post) => post.emoji === '🎮'),
        Thoughts: posts.filter((post) => post.emoji === '🤔'),
        Music: posts.filter((post) => post.emoji === '🎵'),
      },
      recentPosts,
    );
  }
};
