import jsx from './jsx';

/** @deprecated use e() instead */
export const createElement = <T extends Element>(
  tagName: string,
  args: {
    className?: string;
    src?: string;
    innerHTML?: string;
    innerText?: string;
    onError?: OnErrorEventHandlerNonNull;
    onLoad?: () => any;
    onClick?: (this: GlobalEventHandlers, ev: MouseEvent) => any;
    href?: string;
    target?: string;
    style?: string;
    key?: string;
    alt?: string;
    children?: Element[];
  },
): T => {
  const {
    className,
    src,
    innerText,
    innerHTML,
    onError,
    onLoad,
    href,
    target,
    onClick,
    style,
    key,
    alt,
    children,
  } = args;
  const newElement = document.createElement(tagName);

  if (className) {
    newElement.setAttribute('class', className);
  }

  if (innerHTML) {
    newElement.innerHTML = innerHTML;
  } else if (innerText) {
    newElement.innerText = innerText;
  } else if (children) {
    children.forEach((child) => {
      newElement.append(child);
    });
  }

  if (src) {
    (newElement as HTMLImageElement).src = src;
    (newElement as HTMLImageElement).referrerPolicy = 'no-referrer';
  }

  if (onError) {
    (newElement as HTMLImageElement).onerror = onError;
  }

  if (onLoad) {
    (newElement as HTMLImageElement).onload = onLoad;
  }

  if (onClick) {
    newElement.onclick = onClick;
  }

  if (href) {
    (newElement as HTMLAnchorElement).href = href;
    (newElement as HTMLAnchorElement).target = target || '_blank';
    (newElement as HTMLAnchorElement).referrerPolicy = 'no-referrer';
  }

  if (style) {
    (newElement as HTMLElement).setAttribute('style', style);
  }

  if (key) {
    (newElement as HTMLElement).setAttribute('key', key);
  }

  if (alt) {
    (newElement as HTMLElement).setAttribute('alt', alt);
  }

  return newElement as unknown as T;
};

export const deflate = (data: string): string => {
  // @ts-ignore
  const deflated: Uint8Array = pako.deflate(data);
  const deflatedAsUnsafeString = deflated.reduce(
    (data: string, byte: number) => data + String.fromCharCode(byte),
    '',
  );
  return btoa(deflatedAsUnsafeString);
};

export const inflate = (data: string): string => {
  const extracted = atob(data)
    .split('')
    .map((item) => item.charCodeAt(0));
  // @ts-ignore
  const inflatedArray = pako.inflate(extracted);
  const decoder = new TextDecoder();

  return decoder.decode(inflatedArray);
};

export const getElementsByClassName = <T extends Element>(
  className: string,
): T[] => [...document.getElementsByClassName(className)] as T[];

export const findElementByClassName = <T extends Element>(
  className: string,
): T | null => getElementsByClassName<T>(className)?.[0] || null;

export const clamp = (val: number, max: number, min: number): number =>
  Math.max(Math.min(val, max), min);

export const random = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const addHoverEffect = (
  element: Element,
  args?: {
    maxRotate: number;
    maxOffset: number;
    shadowHeight: number;
  },
) => {
  const { maxRotate, maxOffset, shadowHeight } = args || {
    maxRotate: 20,
    maxOffset: 20,
    shadowHeight: 10,
  };

  element.animate(
    {
      boxShadow: `0px 0px ${shadowHeight}px 0 #0005`,
    },
    {
      fill: 'forwards',
      duration: 800,
      easing: 'linear',
    },
  );

  let bounding: DOMRect;
  element.addEventListener('mousemove', (event: Event) => {
    bounding = element.getBoundingClientRect();
    const relativeX = clamp(
      (event as MouseEvent).clientX - bounding.x,
      bounding.width,
      0,
    );
    const relativeY = clamp(
      (event as MouseEvent).clientY - bounding.y,
      bounding.height,
      0,
    );

    const rotateXPercent = relativeX / bounding.width;
    const rotateYPercent = relativeY / bounding.height;

    const xDegrees = Math.floor(maxRotate * rotateXPercent - maxRotate / 2);
    const yDegrees = Math.floor(maxRotate * rotateYPercent - maxRotate / 2);
    const xShadowOffset = Math.floor(
      maxOffset * rotateXPercent - maxOffset / 2,
    );
    const yShadowOffset = Math.floor(
      maxOffset * rotateYPercent - maxOffset / 2,
    );

    element.animate(
      {
        transform: `rotate3d(0, 1, 0, ${xDegrees}deg) rotate3d(1, 0, 0, ${-yDegrees}deg)`,
        boxShadow: `${-xShadowOffset}px ${-yShadowOffset}px ${shadowHeight}px 0 #0005`,
      },
      {
        fill: 'forwards',
        duration: 400,
        easing: 'linear',
      },
    );
  });

  element.addEventListener('mouseout', () => {
    element.animate(
      {
        transform: `rotate3d(0, 1, 0, 0deg) rotate3d(1, 0, 0, 0deg)`,
        boxShadow: `0px 0px ${shadowHeight}px 0 #0009`,
      },
      {
        fill: 'forwards',
        duration: 800,
        easing: 'ease-out',
      },
    );
  });
};

export const makeApiRequest = async <T extends any>(
  path: string,
  method: 'GET' | 'POST',
  body?: Record<string, any>,
) => {
  const isLocal = localStorage.getItem('isLocal');
  const url =
    isLocal === 'true'
      ? `http://localhost:4000/local${path}`
      : `https://api.pfy.ch${path}`;

  const response = await fetch(url, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const json = await response.json();

    return json as T;
  }

  throw new Error(response.statusText);
};

export const skeleton = (width: string, height: string, margin?: string) => (
  <div
    style={`--width: ${width}; --height: ${height}; --margin: ${margin || '0'};`}
    className="skeleton"
  />
);
