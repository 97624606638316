import { skeleton } from './utils';

export const svgDiagrams = () => {
  const rawDiagrams = [
    ...document.querySelectorAll('#mermaid'),
  ] as HTMLPreElement[];

  if (rawDiagrams.length) {
    rawDiagrams.forEach((rawDiagram) => {
      rawDiagram.innerHTML = rawDiagram.querySelector('code')?.innerHTML || '';
    });

    const skeletonElements = rawDiagrams.map((rawDiagram) => {
      const skeletonElement = skeleton('100%', '150px', '0 0 12px');

      rawDiagram.parentNode?.insertBefore(skeletonElement, rawDiagram);

      return skeletonElement;
    });

    const script = document.createElement('script');
    script.src =
      'https://cdn.jsdelivr.net/npm/mermaid@11.2.1/dist/mermaid.min.js';
    script.async = true;
    script.defer = true;
    script.onload = async () => {
      skeletonElements.forEach((skeletonElement) => {
        skeletonElement.remove();
      });
    };
    document.head.appendChild(script);
  }
};
