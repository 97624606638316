import { getElementsByClassName } from './utils';

export const noscriptHandler = () => {
  const elements = [
    ...(getElementsByClassName<HTMLDivElement>('noScript') || []),
  ];

  elements.forEach((element) => {
    element.style.display = 'none';
    element.parentNode?.removeChild(element);
  });
};
