export type Attributes<T extends keyof HTMLElementTagNameMap> = Omit<
  Partial<HTMLElementTagNameMap[T]>,
  'style'
> & {
  style?: string;
};

/** @deprecated Use JSX instead */
export const e = <T extends keyof HTMLElementTagNameMap>(
  tag: T,
  attributes: Attributes<T> | null,
  children: (string | HTMLElement)[] | string | HTMLElement = [],
) => {
  const element = document.createElement(tag);

  Object.entries(attributes || {}).forEach(([key, value]) => {
    const castKey = key as keyof HTMLElementTagNameMap[T];

    // Since we're replacing the style attribute this complains heaps
    // @ts-ignore
    element[castKey] = value;
  });

  if (Array.isArray(children)) {
    children.forEach((child) => {
      element.append(child);
    });
  } else {
    element.append(children);
  }

  return element;
};
