import { colourMode, setColourMode } from './colour-mode';

export const setHeader = () => {
  const root = document.documentElement;
  const heading = document.getElementsByClassName('controls');
  if (heading[0]) {
    let mainHeading = heading[0];
    let controls = document.createElement('span');
    let themeToggle = document.createElement('span');

    themeToggle.setAttribute('role', 'button');
    themeToggle.setAttribute('tabIndex', '0');
    themeToggle.setAttribute('class', 'themeToggle');
    themeToggle.setAttribute('title', 'Change theme');
    themeToggle.append(colourMode === 'light' ? '🌕' : ' 🌑');
    themeToggle.title = 'Toggle Light / Dark Mode';
    themeToggle.onclick = () => {
      window.localStorage.setItem(
        'color-mode',
        colourMode === 'light' ? 'dark' : 'light',
      );
      setColourMode();
      themeToggle.innerHTML = colourMode === 'light' ? '🌕' : ' 🌑';
    };

    controls.appendChild(themeToggle);
    mainHeading.append(controls);
  }
};
