import { getElementsByClassName, makeApiRequest, skeleton } from '../utils';
import { e } from '../elements/element';

const getStats = async (): Promise<number> => {
  const response = await makeApiRequest<{ count: number }>(
    '/goatcounter/count',
    'GET',
  );

  return response.count;
};

const expectedDigits = 4;
export const setupCatCount = async () => {
  const cats = getElementsByClassName<HTMLDivElement>('catCounter');
  cats.forEach((cat) =>
    cat.append(skeleton(`${expectedDigits * 35}px`, '75px')),
  );

  if (cats.length > 0) {
    const count = await getStats();

    for (const cat of cats) {
      cat.innerHTML = '';

      count
        .toString()
        .replaceAll(' ', '')
        .split('')
        .forEach((char) => {
          cat.append(
            e('img', { src: `/scripts/cat-count/assets/${char}.gif` }),
          );
        });
    }
  }
};
