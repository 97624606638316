import jsx from './jsx';
import { getElementsByClassName, makeApiRequest } from './utils';

export const handleWebmentions = async () => {
  const target = getElementsByClassName('webmentions')?.[0];

  if (target) {
    const mentions = await makeApiRequest<
      {
        target: string;
        source: string;
        title: string;
        createdAt: string;
      }[]
    >('/webmention/query', 'POST', {
      target: window.location.href,
    });

    console.log('Webmentions', mentions);
    target.replaceChildren(
      <a href={'https://indieweb.org/Webmention'}>Mention via Webmention</a>,
      <ul>
        {...mentions.map((mention) => (
          <li>
            Mentioned {new Date(mention.createdAt).toLocaleDateString()} on:{' '}
            <a href={mention.source}>
              {mention.title} @ {new URL(mention.source).hostname}
            </a>
          </li>
        ))}
      </ul>,
      <hr />,
    );
  }
};
